import React, {
  useState,
  useEffect,
} from 'react'
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Lottie from 'react-lottie';


import './Vessels.css'
import * as WhiteSpinner from '../../assets/animations/white_spinner.json'

import NavBar from '../../components/NavBar/NavBar'

import SearchIcon from '../../assets/svg_icons/search.svg'
import ArrowDropDown from '../../assets/svg_icons/arrowdropdown.svg'

import { 
  change_vessel_status, 
  fetch_vessels, 
  search_vessel 
} from '../../api/vessels'

export default function VesselsList(props) {
  const navigate = useNavigate()
  const [anchorElement, setAnchorElement] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  const isOptionsOpen = Boolean(anchorElement);
  const [input_values, setInputValues] = useState({
    search_query: '',
  })
  const [veessels, setVessels] = useState([])
  

  useEffect(() => {
    _fetch_vessels()
  }, [])

  const _fetch_vessels = async () => {
    try {
      var res = await fetch_vessels()
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setVessels(response.data)
      } else {
        console.log('response message', response.message)
        setVessels([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log('error message', error.message)
      setVessels([])
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const _search_vessel = async (query) => {
    try {
      var res = await search_vessel(query)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setVessels(response.data)
      } else {
        console.log('response message', response.message)
        setVessels([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log('error message', error.message)
      setVessels([])
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });

    if(name === 'search_query') {
      if(value === '') {
        _fetch_vessels()      
        return
      }
      _search_vessel(value)
      return
    }
  }

  const edit_vessel = (item) => {
    navigate('/edit-vessel', {
      state: {
        vessel: item,
      }
    })
  }

  const closeOptions = () => {
    setAnchorElement(null);
  };

  const selectStatus = (event, item) => {
    setAnchorElement(event.currentTarget);
    setCurrentItem(item)
  };

  const handleChangeStatus = async new_status => {
    closeOptions()
    setIsChangingStatus(true)

    try {
      var params = {
        vessel_id: currentItem.id,
        new_status,
      }

      var res = await change_vessel_status(params)
      var response = await res.json()
      if (res.ok) {
        await _fetch_vessels()
        setIsChangingStatus(false)
        Store.addNotification({
          title: "Success",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        setIsChangingStatus(false)
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setIsChangingStatus(false)
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }


  const defaultAnimationOptions = {
    loop: true,
    autoplay: true, 
    animationData: WhiteSpinner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <div className='veessels-container'>
      <NavBar log_out={props.log_out} />
      <div className='veessels-content'>
        <div className='top-bar'>
          {/* <form> */}
            <span className='search-container'>
              <img
                src={SearchIcon}
                className="search-icon"
                alt='Search'
              />
              <input 
                type="text"
                value={input_values.search_query} 
                name='search_query'
                placeholder='Search'
                onChange={handle_input_change} 
                required
                className="search-input"  
              />
            </span>            
          {/* </form> */}
          <a className='add-new' href='/add-vessel'>
            Add New
          </a>
        </div>
        <div class="fixTableHead">
          <table className='table'>
            <thead className='thead'>
              <th 
                className='th'
                style={{
                  width: '30%',
                  paddingLeft: 20,
                }} 
              >
                Name
              </th>
              <th className='th'>
                Type
              </th>
              <th className='th'>
                Classification
              </th>
              <th className='th'>
                Year
              </th>
              <th className='th'>
                Availability
              </th>
              {/* <th style={{width: 0}}></th> */}
            </thead>
            <tbody className='tbody'>
              {
                veessels.map((item, index) => {
                  return(
                    <tr 
                      className='tr'                       
                      key={index}
                    >
                      
                      <td 
                        className='td'
                        style={{
                          width: '30%',
                          paddingLeft: 20,
                        }} 
                        onClick={() => edit_vessel(item)} 
                      >
                        {item.name}
                      </td>
                      <td className='td' onClick={() => edit_vessel(item)} >
                        {item.type}
                      </td>
                      <td className='td' onClick={() => edit_vessel(item)} >
                        {item.classification}
                      </td>
                      <td className='td' onClick={() => edit_vessel(item)} >
                        {item.year}
                      </td>
                      <td 
                        className='td vessel-availability'
                        onClick={(event) => {
                          if (!isChangingStatus)  selectStatus(event, item)
                        }} 
                      >
                        {item.availability}
                        {
                          isChangingStatus ? (
                            <Lottie options={defaultAnimationOptions}
                              height={100}
                              // width={100}
                              isStopped={false}
                            />
                          ) : (
                            <img 
                              src={ArrowDropDown}
                              style={{
                                width: '10px',
                                marginLeft: '10px'
                              }}     
                              alt="Arrow Down"           
                            />
                          )
                        }
                        
                      </td>
                      {/* <td 
                        style={{
                          width: 30,
                          cursor: 'pointer',
                          zIndex: 9999999,
                        }}
                        onClick={() => delete_vessel(item, index)}
                      >
                        <img 
                          src={TrashIcon}
                          style={{
                            width: 15,
                          }}
                        />
                      </td> */}
                    </tr> 
                  )
                })
              }
                                          
            </tbody>
          </table>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={isOptionsOpen}
        onClose={closeOptions}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleChangeStatus('Available')}>
          <ListItemText>Available</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleChangeStatus('In Use')}>
          <ListItemText>In Use</ListItemText>
        </MenuItem>        
        <MenuItem onClick={() => handleChangeStatus('Under Maintenance')}>
          <ListItemText>Under Maintenance</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}