import {baseURL} from './env'

export const fetch_certifications = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'certifications/fetch_certifications'
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const search_certification = async (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `certifications/search_certification?query=${query}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const add_certification = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'certifications/certifications_api'
      var token = localStorage.getItem('token')

      var formdata = new FormData()

      Object.keys(params).forEach(key => {    
        key === 'cert_img' ? (
          formdata.append("image", params.cert_img.image[0])
        ) : formdata.append(key, params[key]);
      })

      var response = await fetch(url, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
          // Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: formdata,
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const edit_certifications = async (id, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `certifications/certifications_api?id=${id}`
      var token = localStorage.getItem('token')

      var formdata = new FormData()

      Object.keys(params).forEach(key => {    
        key === 'cert_img' ? (
          formdata.append("image", params.cert_img.image[0])
        ) : formdata.append(key, params[key]);
      })

      var response = await fetch(url, {
        method: 'PUT',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
          // Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: formdata,
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const delete_vessel = async id => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `vessels/vessels_api?id=${id}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

