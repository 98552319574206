import React, {
  useEffect,
  useState,
} from 'react'
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom'
import { Store } from 'react-notifications-component';

import '../Vessels/Vessels.css'
import './Users.css'

import {baseURL2} from '../../api/env'

import NavBar from '../../components/NavBar/NavBar'

import SearchIcon from '../../assets/svg_icons/search.svg'
import TrashIcon from '../../assets/svg_icons/trash.svg'
import CloseIcon from '../../assets/svg_icons/close_1.svg'
import UploadIcon from '../../assets/svg_icons/upload.svg'
import Cert2 from '../../assets/images/cert_logos/bimco.png'

import {add_certification, search_certification, edit_certifications, fetch_certifications} from '../../api/certification'

export default function Certifications(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate()
  const [input_values, setInputValues] = useState({
    search_query: '',
    title: '',
    decription: '',
    year: '',
  })
  const [certs, setCerts] = useState([])
  const [cert_img, setCertImg] = useState(null);
  const [cert_img_name, setCertImgName] = useState('');

  const [submit_action, setSubmitAction] = useState('add'); // edit
  const [modal_title, setModalTitle] = useState('Add New Certification'); // edit
  const [edit_item_id, setEditItemId] = useState(null)
  

  useEffect(() => {
  _fetch_certifications()
  }, [])

  const _fetch_certifications = async () => {
    try {
      var res = await fetch_certifications()
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setCerts(response.data)
      } else {
        console.log('response message', response.message)
        setCerts([])
      }
    } catch (error) {
      console.log('error message', error.message)
      setCerts([])
    }
  }

  const _search_certification = async (query) => {
    try {
      var res = await search_certification(query)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setCerts(response.data)
      } else {
        console.log('response message', response.message)
        setCerts([])
      }
    } catch (error) {
      console.log('error message', error.message)
      setCerts([])
    }
  }

  const sanitize_msg = msg => {
    // remove urls
    var text = msg.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
    // remove html tags
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if ([event.target.name] == 'image') {
			setCertImg({
				image: event.target.files,
			});
      setCertImgName(event.target.files[0].name)
			return
		}


    setInputValues({
      ...input_values,
      [name]: value
    });

    if(name === 'search_query') {
      if(value === '') {
        _fetch_certifications()      
        return
      }
      _search_certification(value)
      return
    }
  }

  const edit_cert = (item) => {
    setInputValues({
      ...input_values,
      title: item.title,
      decription: item.decription,
      year: item.year,
    })
    setCertImgName('')
    setCertImg(null)
    setEditItemId(item.id)
    setSubmitAction('edit')
    setModalTitle('Edit Certification')
    openModal()
  }

  const add_cert = () => {
    setInputValues({
      ...input_values,
      title: '',
      decription: '',
      year: '',
    })
    setCertImgName('')
    setCertImg(null)
    setSubmitAction('add')
    setModalTitle('Add New Certification')
    openModal()
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handle_submit = async (event) => {
    event.preventDefault();

    switch (submit_action) {
      case 'add':
        submit_add()
        break;
      case 'edit':
        console.log('editing')
        submit_edit()
        break;
    
      default:
        break;
    }
  }

  const submit_add = async () => {    
    try {
      var params = {
        title: input_values.title,
        decription: input_values.decription,
        year: input_values.year,
        cert_img,
      }
      var res = await add_certification(params)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setInputValues({
          ...input_values,
          title: '',
          decription: '',
          year: '',
        })
        setCertImgName('')
        setCertImg(null)
        _fetch_certifications()
        closeModal()
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const submit_edit = async () => {    
    try {
      var params = {
        title: input_values.title,
        decription: input_values.decription,
        year: input_values.year,
        // cert_img,
      }
      console.log('params', params)
      var res = await edit_certifications(edit_item_id, params)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setInputValues({
          ...input_values,
          title: '',
          decription: '',
          year: '',
        })
        setCertImgName('')
        setCertImg(null)
        setEditItemId(null)
        _fetch_certifications()
        closeModal()
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const customStyles = {
    content: {
      // top: '50%',
      // left: '50%',
      // right: 'auto',
      // bottom: 'auto',
      // marginRight: '-50%',
      zIndex: 9999999999999999,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(20px)',
    }
  };
  
  return (
    <div className='veessels-container'>
      <NavBar log_out={props.log_out} />
      <div className='veessels-content'>
        <div className='top-bar'>
          {/* <form> */}
            <span className='search-container'>
              <img
                src={SearchIcon}
                className="search-icon"
              />
              <input 
                type="text"
                value={input_values.search_query} 
                name='search_query'
                placeholder='Search'
                onChange={handle_input_change} 
                required
                className="search-input"  
              />
            </span>            
          {/* </form> */}
          <div className='add-new' onClick={() => add_cert()}>
            Add New
          </div>
        </div>
        <div class="fixTableHead">
          <table className='table'>
            <thead className='thead'>
              <th 
                className='th'
                style={{
                  paddingLeft: 20,
                }} 
              >
              </th>
              <th 
                className='th'
                style={{
                  width: '30%',
                }} 
              >
                Title
              </th>
              <th className='th'>
                Year
              </th>
              <th 
                className='th cert-decription-head'
              >
                Description
              </th>
            </thead>
            <tbody className='tbody'>
              {
                certs.map((item, index) => {
                  return (
                    <tr className='tr' onClick={() => edit_cert(item)} key={index}>
                      <td 
                        className='td'
                        style={{
                          paddingLeft: 20,
                        }} 
                      >
                        <img 
                          src={baseURL2 + item.image}
                          className="cert-img"
                        />
                      </td>
                      <td 
                        className='td'
                        
                      >
                        {item.title}
                      </td>
                      <td className='td'>
                        {item.year}
                      </td>
                      <td className='td cert-decription'>
                        {item.decription}
                      </td>
                    </tr>  
                  )
                })
              }
                                           
                                      
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className="vessel-modal" 
      >
         <button
          className='modal-close-button'
          onClick={closeModal}
        >
          <img 
            src={CloseIcon}
            alt='Close Button'
          />
        </button>
        <div className='user-content'>          
          <p className='auth-title'>
            {modal_title}
          </p>
          <form onSubmit={handle_submit}>
            <input 
              type='text' 
              value={input_values.title} 
              name='title'
              placeholder='Title'
              onChange={handle_input_change} 
              required
              className="auth-input"                 
            />   
            <input 
              type='text' 
              value={input_values.year} 
              name='year'
              placeholder='Year'
              onChange={handle_input_change} 
              required
              className="auth-input"                 
            />   
            <textarea
              value={input_values.decription} 
              name='decription'
              placeholder='Description...'
              className='cert-desc'
              onChange={handle_input_change} 
              required              
            /> 
            <label for="cert_img_tag" className='file-input-label-2 boxsizingBorder'>
              <br/>
              <span className='file-input-label-2-span'>
                <img 
                  src={UploadIcon}
                  className='file-upload-2'
                />
                <p>
                  Upload Image
                </p>
              </span>
              
              <input 
                id="cert_img_tag" 
                type="file"
                className='file-input'
                name="image"
                onChange={handle_input_change}
                accept="image/png, image/jpg, image/jpeg"
                // required
              />
              <br/>
              <span id="cert_img_name">{cert_img_name}</span>
            </label>
            <input type="submit" value="Submit" className="auth-submit-button "/>
          </form>
        </div>
      </Modal>
    </div>
  )
}