
export const saveData = async (key, value) => {
  if (key && value) {
    try {
      await localStorage.setItem(key, value);
    } catch (error) {
      console.log(error)
    }
  }
  
};

export const saveObject = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value)
    await localStorage.setItem(key, jsonValue)
  } catch (error) {
    console.log(error);
  }
}

export const getObject = async (key) => {
  
  try {
    const jsonValue = await localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.log(e);
  }
};

export const getData = async (key) => {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.log(`${key} not found`);
  }
  
};


export const removeData = async (key) => {
  try {
    await localStorage.removeItem(key);
    console.log('User data cleared');
  } catch (e) {
    console.log(e);
  }
  
}
