import React, {
  useState,
  useEffect,
} from 'react'
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom'

import './MailingLists.css'

import NavBar from '../../components/NavBar/NavBar';

import VesselIcon from '../../assets/svg_icons/vessel.svg'

export default function MailingLists(props) {
  return (
    <div className='mailing-list-container'>
      <NavBar log_out={props.log_out} />
      <div className='mailing-list-content'>
        <p
          className='mailing-list-title'
        >
          Mailing Lists
        </p>

        <div className='mailing-lists-grid'>
          <a className='mailing-lists-card' href="/vessel-status-list">
            <img 
              src={VesselIcon}
              className='mailing-lists-card-icon'
            />
            <p className='mailing-lists-card-title'>
              Vessel Status Mailing List
            </p>
            <p className='mailing-lists-card-subtitle'>
              Sends out email updates whenever there's an update to the availability status of a vessel
            </p>
          </a>
        </div>

      </div>
    </div>
  )
}