import {baseURL} from './env'

export const login = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await fetch(baseURL + 'account/log_in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...params
        }),
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const reset_password = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await fetch(baseURL + 'account/reset_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...params
        }),
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const set_password = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var response = await fetch(baseURL + 'account/set_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...params
        }),
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const register_user = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var token = localStorage.getItem('token')
      var response = await fetch(baseURL + 'account/register_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: JSON.stringify({
          ...params
        }),
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const fetch_users = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'account/fetch_users'
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const search_users = async (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `account/search_users?query=${query}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}