import {baseURL} from './env'

export const fetch_clients = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'clients/manage_clients'
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const search_client = async (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `clients/search_client?query=${query}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const fetch_unsubcribed_vessel_status_update_clients = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `clients/fetch_unsubcribed_vessel_status_update_clients`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const add_client = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'clients/manage_clients'
      var token = localStorage.getItem('token')    

      var response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: JSON.stringify(params),
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const edit_client = async (id, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `clients/manage_clients?id=${id}`
      var token = localStorage.getItem('token')

      var response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: JSON.stringify(params),
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const delete_vessel = async id => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `vessels/vessels_api?id=${id}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

