import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link';
import './NavBar.css'

import { sleep } from "../../utils/sleep";

import Logo from '../../assets/images/GMS-Logo.png'

import ArrowDropDown from '../../assets/svg_icons/arrowdropdown.svg'
import ArrowDropUP from '../../assets/svg_icons/arrowdropup.svg'
import CloseIcon from '../../assets/svg_icons/close.svg'
import MenuIcon from '../../assets/svg_icons/menu.svg'
import LogOutIcon from '../../assets/svg_icons/log_out.svg'

const MenuList = [
  {
    title: 'Vessels',
    url: '/',
    sub_menu: [],
  },
  // {
  //   title: 'Posts',
  //   url: `/posts`,
  //   sub_menu: [],
  // },
  {
    title: 'Users',
    url: `/users`,
    sub_menu: [],
  },        
  {
    title: 'Clients',
    url: `/clients`,
    sub_menu: [],
  },        
  {
    title: 'Mailing Lists',
    url: `/mailing-lists`,
    sub_menu: [],
  },        
  {
    title: 'Certifications',
    url: `/certifications`,
    sub_menu: [],
  },        
]

export default function NavBar (props) {
  const [is_menu_opened, setMenuOpened] = React.useState(false);
  const [show_sub_menu, setShowSubMenu] = React.useState(false);
  const [active_sub_menu_index, setActiveSubMenuIndex] = React.useState();
  const location = useLocation()
  const navigate = useNavigate()

  const log_out = () => {
    props.log_out()
    navigate('/login', {replace: true})
  }


  const close_menu = async () => {
    
    setMenuOpened(false)
    
    await sleep(1000)
  }

  const toggle_sub_menu = (index) => {
    if (active_sub_menu_index === index) {
      setShowSubMenu(false)
      setActiveSubMenuIndex(null)
      close_menu()
    } else {
      setActiveSubMenuIndex(index)
      setShowSubMenu(true)
    }
  }

  const menu_list = MenuList.map((item, index, array) => {
    if(item.sub_menu.length === 0) {
      return (
        <div 
          className='nav-item'
          key={index}
        >          
          <NavHashLink 
            exact to={item.url} 
            smooth
            className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link"}
            onClick={close_menu}            
          >
            {item.title}
          </NavHashLink>
        </div>
      )
    } else {
      return (
        <div 
          className='nav-item-2'
          key={index}
          
        >
          <div 
            className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link-2"}
            onClick={() => toggle_sub_menu(index)}            
          >
            <>
              {item.title}
            </>
            {
              (show_sub_menu && active_sub_menu_index === index) ? (
                <img 
                  src={ArrowDropUP}
                  style={{
                    width: '10px',
                  }}   
                  alt='Arrow Up'             
                />
              ) : (
                <img 
                  src={ArrowDropDown}
                  style={{
                    width: '10px',
                  }}     
                  alt="Arrow Down"           
                />
              )
            }
          </div>
          {
            show_sub_menu && active_sub_menu_index === index && (
              <div className='sub-menu'>
                {
                  item.sub_menu.map((sub_item, sub_index) => {
                    return (
                      <div 
                        className='sub-item'
                        key={sub_index}
                      >          
                        <NavHashLink 
                          exact to={sub_item.url} 
                          smooth
                          className='sub-item-link'
                          onClick={() => toggle_sub_menu(index)}            
                        >
                          {sub_item.title}
                        </NavHashLink>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
        </div>
      )
    }
    
  })

  const mobile_menu_list = MenuList.map((item, index, array) => {
    if(item.sub_menu.length === 0) {
      return (
        <div 
          className='nav-item'
          key={index}
        >          
          <NavHashLink 
            exact to={item.url} 
            smooth
            className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link"}
            onClick={close_menu}            
          >
            {item.title}
          </NavHashLink>
        </div>
      )
    } else {
      return (
        <div 
          className='nav-item-2'
          key={index}
          
        >
          <div 
            className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link-2"}
            onClick={() => toggle_sub_menu(index)}            
          >            
            {
              (show_sub_menu && active_sub_menu_index === index) ? (
                <img 
                  src={ArrowDropUP}
                  style={{
                    width: '10px',
                  }}      
                  alt='Arrow Up'          
                />
              ) : (
                <img 
                  src={ArrowDropDown}
                  style={{
                    width: '10px',
                  }}  
                  alt='Arrow  Down'              
                />
              )
            }
            <>
              {item.title}
            </>
          </div>
          {
            show_sub_menu && active_sub_menu_index === index && (
              <div className='sub-menu'>
                {
                  item.sub_menu.map((sub_item, sub_index) => {
                    return (
                      <div 
                        className='sub-item'
                        key={sub_index}
                      >          
                        <NavHashLink 
                          exact to={sub_item.url} 
                          smooth
                          className='sub-item-link'
                          onClick={() => toggle_sub_menu(index)}            
                        >
                          {sub_item.title}
                        </NavHashLink>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
        </div>
      )
    }
    
  })

  return (
    <>
      <div className='nav-bar'>
        <div className='nav-bar-logo-tagline'>
          <a href='/'>
            <img 
              src={Logo}
              className='logo'
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
            />
          </a>  
          <p>Integrity in service...</p>
        </div>

        <div className={"nav-list"}>
          {menu_list}
        </div>

        <div className='log-out-btn' onClick={log_out}>
          <img
            src={LogOutIcon}
            className="log-out-icon"
            alt='Log Out'
          />
          <p>Log Out</p>
        </div>
        
      </div>
      <div className='mobile-nav'>
        <div className='mobile-nav-bar'>
          <div className='mobile-nav-bar-header'>          
            <a href="/">
            <img 
              src={Logo}
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
              className='mobile-logo'
            />
            </a>

            <div className="menu-icon" onClick={() => setMenuOpened(true)}>
              <img
                src={MenuIcon}
                alt='Menu Icon'
                className='menu-icon-img'
              />
            </div>
          </div>
          <div 
            // className='mobile-nav-drawer'
            className={is_menu_opened ? 'mobile-nav-drawer-open' : 'mobile-nav-drawer-close'}
          >
            <div 
              className='mobile-nav-drawer-overlay'
              onClick={() => setMenuOpened(false)}
            />
            <div className='mobile-nav-drawer-content'>
              <div className='mobile-drawer-header'>  
                <div className="menu-icon" onClick={() => setMenuOpened(false)}>
                  <img
                    src={CloseIcon}
                    alt='Menu Icon'
                    className='menu-icon-img'
                  />
                </div>        
                <a href="/">
                <img 
                  src={Logo}
                  alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
                  className='mobile-logo'
                />
                </a>              
              </div>
              <div className="mobile-nav-list">
                {mobile_menu_list}
              </div>
              <div className='log-out-btn' onClick={log_out}>
                <img
                  src={LogOutIcon}
                  className="log-out-icon"
                  alt='Log Out'
                />
                <p>Log Out</p>
              </div>
            </div>
            
          </div>
          
          {/* <ul className={is_menu_icon_clicked ? "menu-list" : "menu-list close"}>
            {menuList}
          </ul> */}
        </div>
      </div>
    </>
  )
}