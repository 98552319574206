import React, {
  useState,
  useEffect,
} from 'react'
import { Store } from 'react-notifications-component';

import './Vessels.css'

import NavBar from '../../components/NavBar/NavBar'

import SearchIcon from '../../assets/svg_icons/search.svg'
import { useNavigate } from 'react-router-dom'
import { fetch_clients, search_client } from '../../api/clients'

export default function ClientList(props) {
  const navigate = useNavigate()
  const [input_values, setInputValues] = useState({
    search_query: '',
  })
  const [clients, setClients] = useState([])

  useEffect(() => {
    _fetch_clients()
  }, [])

  const _fetch_clients = async () => {
    try {
      var res = await fetch_clients()
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setClients(response.data)
      } else {
        console.log('response message', response.message)
        setClients([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log('error message', error.message)
      setClients([])
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const _search_client = async (query) => {
    try {
      var res = await search_client(query)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setClients(response.data)
      } else {
        console.log('response message', response.message)
        setClients([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log('error message', error.message)
      setClients([])
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });

    if(name === 'search_query') {
      if(value === '') {
        _fetch_clients()      
        return
      }
      _search_client(value)
      return
    }
  }

  const edit_client = (item) => {
    navigate('/edit-client', {
      state: {
        client: item,
      }
    })
  }

  return (
    <div className='veessels-container'>
      <NavBar log_out={props.log_out} />
      <div className='veessels-content'>
        <div className='top-bar'>
          {/* <form> */}
            <span className='search-container'>
              <img
                src={SearchIcon}
                className="search-icon"
              />
              <input 
                type="text"
                value={input_values.search_query} 
                name='search_query'
                placeholder='Search'
                onChange={handle_input_change} 
                required
                className="search-input"  
              />
            </span>            
          {/* </form> */}
          <a className='add-new' href='/add-client'>
            Add New
          </a>
        </div>
        <div class="fixTableHead">
          <table className='table'>
            <thead className='thead'>
              <th 
                className='th'
                style={{
                  width: '30%',
                  paddingLeft: 20,
                }} 
              >
                Name
              </th>
              <th className='th'>
                Email
              </th>
              <th className='th'>
                Phone
              </th>
              <th className='th'>
                Address
              </th>
              {/* <th className='th'>
                Availability
              </th> */}
              {/* <th style={{width: 0}}></th> */}
            </thead>
            <tbody className='tbody'>
              {
                clients.map((item, index) => {
                  return(
                    <tr className='tr' onClick={() => edit_client(item)} key={index}>
                      
                      <td 
                        className='td'
                        style={{
                          width: '30%',
                          paddingLeft: 20,
                        }} 
                      >
                        {item.company_name}
                      </td>
                      <td className='td'>
                        {item.company_email}
                      </td>
                      <td className='td'>
                        {item.company_phone_number}
                      </td>
                      <td className='td'>
                        {item.address}
                      </td>
                      {/* <td 
                        style={{
                          width: 30,
                          cursor: 'pointer',
                          zIndex: 9999999,
                        }}
                        onClick={() => delete_vessel(item, index)}
                      >
                        <img 
                          src={TrashIcon}
                          style={{
                            width: 15,
                          }}
                        />
                      </td> */}
                    </tr> 
                  )
                })
              }
                                          
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}