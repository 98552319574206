import {baseURL} from './env'

export const fetch_vessel_status_list = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'mailing_list/manage_vessel_status_update'
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const search_vessel_status_mailing_list = async (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `mailing_list/search_vessel_status_mailing_list?query=${query}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const subscribe_client_to_vessel_status_mailing_list = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'mailing_list/subscribe_client_to_vessel_status_mailing_list'
      var token = localStorage.getItem('token')       

      var response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: JSON.stringify(params),
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const add_new_vessel_status_contact = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'mailing_list/manage_vessel_status_update'
      var token = localStorage.getItem('token')       

      var response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: JSON.stringify(params),
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const edit_new_vessel_status_contact = async (id, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `mailing_list/manage_vessel_status_update?id=${id}`
      var token = localStorage.getItem('token')

      var response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: JSON.stringify(params),
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const delete_vessel_status_contact = async id => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `mailing_list/manage_vessel_status_update?id=${id}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

