import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Store } from 'react-notifications-component';

import './Auth.css'
import Logo from '../../assets/images/GMS-Logo.png'

import { 
  reset_password
} from '../../api/auth'

export default function RecoverPassword(props) {
  const navigate = useNavigate()
  const [input_values, setInputValues] = useState({    
    email: '',
  })

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });
  }

  const handle_submit = async (event) => {
    event.preventDefault();
    

    try {
      var res = await reset_password(input_values)
      var response = await res.json()
      if(res.ok) {
        Store.addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        navigate("/set-password", {
          state: {
            email: input_values.email,
          }
        })
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  return (
    <div className='auth-container'>
      <img 
        src={Logo}
        className='auth-logo'
      />
      <div className='auth-content'>
        <p className='auth-title'>
          Forgot Password?
        </p>
        <p className='auth-subtitle'>
          Don’t worry, happens to the best of us
        </p>
        
        <form onSubmit={handle_submit}>
          <input 
            type='email' 
            value={input_values.email} 
            name='email'
            placeholder='Enter email address'
            onChange={handle_input_change} 
            required
            className="auth-input"                 
          />   
          <input type="submit" value="Submit" className="auth-submit-button "/>          
        </form>
      </div>
    </div>
  )
}