import React, {
  useState,
} from 'react'
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom'

import './Vessels.css'

import NavBar from '../../components/NavBar/NavBar'

import { add_client } from '../../api/clients'

export default function AddClient(props) {
  const navigate = useNavigate()
  const [input_values, setInputValues] = useState({
    company_name: '',
    company_email: '',
    company_phone_number: '',
    address: '',
    contact_name: '',
    contact_email: '',
    contact_phone_number: '',
  })

  const sanitize_msg = msg => {
    // remove urls
    var text = msg.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
    // remove html tags
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }

  
  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;


    setInputValues({
      ...input_values,
      [name]: value
    });
  }

  const handle_submit = async (event) => {
    event.preventDefault();

    try {
      var res = await add_client(input_values)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        navigate('/clients')
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  return (
    <div className='veessels-container'>
      <NavBar log_out={props.log_out} />
      <div className='add-vessels-section'>
        <p
          className='add-vessel-title'
        >
          Add New Clients
        </p>
        
        <form className='vessel-form' onSubmit={handle_submit} >
          <div className='vessels-input-fields'>            
            <span className='vessel-input'>
              <p className='vessel-label'>
                Company Name 
              </p>
              <input 
                type='text'
                value={input_values.company_name} 
                name='company_name'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Company Email 
              </p>
              <input 
                type='email'
                value={input_values.company_email} 
                name='company_email'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Company Phone 
              </p>
              <input 
                type='tel'
                value={input_values.company_phone_number} 
                name='company_phone_number'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
              <p className='form-note'>
                Phone number must be in internatonal format and also include the plus (+) sign.
              </p>
            </span>
          </div>
          <span className='vessel-description-input boxsizingBorder'>
            <p className='vessel-label' >
              Address
            </p>
            <textarea
              value={input_values.address} 
              name='address'
              placeholder='Type here'
              onChange={handle_input_change} 
              className='boxsizingBorder'
            /> 
          </span>
          <p
            className='add-vessel-subtitle'
            style={{marginTop: 30}}
          >
            Contact Details
          </p>
          <div className='vessels-input-fields'>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Contact Name 
              </p>
              <input 
                type='text'
                value={input_values.contact_name} 
                name='contact_name'
                placeholder='Type here'
                onChange={handle_input_change} 
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Contact Email 
              </p>
              <input 
                type='email'
                value={input_values.contact_email} 
                name='contact_email'
                placeholder='Type here'
                onChange={handle_input_change} 
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Contact Phone Number 
              </p>
              <input 
                type='tel'
                value={input_values.contact_phone_number} 
                name='contact_phone_number'
                placeholder='Type here'
                onChange={handle_input_change} 
                className="vessel-input-field"                 
              /> 
              <p className='form-note'>
                Phone number must be in internatonal format and also include the plus (+) sign.
              </p>
            </span>
          </div>

          <span
            style={{
              display: 'flex',
              textAlign: 'center',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 40,
              marginBottom: 40,
            }}
          >
            <input type="submit" value="SUBMIT" className="submit-button "/>
          </span>
        </form>
      </div>
    </div>
  )
}