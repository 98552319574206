import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ReactNotifications } from 'react-notifications-component'

import './App.css';
import 'react-notifications-component/dist/theme.css'

import Login from './Pages/Auth/Login'
import RecoverPassword from './Pages/Auth/RecoverPassword'
import SetPassword from './Pages/Auth/SetPassword'

import AddVessel from './Pages/Vessels/AddVessel'
import AddClient from './Pages/clients/AddClient'
import EditClient from './Pages/clients/EditClient'
import EditVessel from './Pages/Vessels/EditVessel'
import ClientList from './Pages/clients/ClientList'
import MailingLists from './Pages/MailingLists'
import VesselsList from './Pages/Vessels/VesselsList'
import UserList from './Pages/Users/UserList'
import Certifications from './Pages/Users/Certifications'
import VesselStatusList from './Pages/MailingLists/VesselStatusList'

import {getObject, removeData, saveObject} from './utils/handleStorage'

function App() {
  const [is_authenticated, set_is_authenticated] = React.useState(true)
  React.useEffect(() => {  
    const get_user = async () => {
      let user = await getObject('user')
      console.log('user', user)
      if (user === null || user === undefined) {
        // console.log('no user!!!')
        saveObject('user', {is_authenticated: false})
        set_is_authenticated(false)
        return {is_authenticated: false}
      } else {
        set_is_authenticated(user.is_authenticated)
        
      }
    }
    get_user()    
    
  }, [])

  const log_out = () => {
    saveObject('user', {is_authenticated: false})
    set_is_authenticated(false)
    removeData('token')
  }
  
  return (
    <div className="App">
      <ReactNotifications />
      <Router>                
        <Routes>
          <Route path="/login" element={<Login log_in={() => set_is_authenticated(true)}/>} />          
          <Route path="/recover-password" element={<RecoverPassword />} />          
          <Route path="/set-password" element={<SetPassword />} />   

          <Route 
            path="/" 
            element={
              <RequireAuth>
                <VesselsList log_out={log_out} />
              </RequireAuth>
            } 
           />          
          <Route 
            path="/add-client" 
            element={
              <RequireAuth>
                <AddClient log_out={log_out} />
              </RequireAuth>
            } 
           />          
          <Route 
            path="/clients" 
            element={
              <RequireAuth>
                <ClientList log_out={log_out} />
              </RequireAuth>
            } 
           />          
          <Route 
            path="/edit-client" 
            element={
              <RequireAuth>
                <EditClient log_out={log_out} />
              </RequireAuth>
            } 
           />          
          <Route 
            path="/add-vessel" 
            element={
              <RequireAuth>
                <AddVessel log_out={log_out} />
              </RequireAuth>
            } 
           />          
          <Route 
            path="/edit-vessel" 
            element={
              <RequireAuth>
                <EditVessel log_out={log_out} />
              </RequireAuth>
            } 
           />          
          <Route 
            path="/mailing-lists" 
            element={
              <RequireAuth>
                <MailingLists log_out={log_out} />
              </RequireAuth>
            } 
           />          
          <Route 
            path="/users" 
            element={
              <RequireAuth>
                <UserList log_out={log_out} />
              </RequireAuth>
            } 
           />          
          <Route 
            path="/certifications" 
            element={
              <RequireAuth>
                <Certifications log_out={log_out} />
              </RequireAuth>
            } 
           />                    
          <Route 
            path="/vessel-status-list" 
            element={
              <RequireAuth>
                <VesselStatusList log_out={log_out} />
              </RequireAuth>
            } 
           />                    
          <Route path="*" element={<Navigate to={is_authenticated ? "/" : "/login"} />} />         
        </Routes>
      </Router>
    </div>
  );
}

export default App;

function RequireAuth({ children }) {
  const [is_authenticated, set_is_authenticated] = React.useState(true)
  React.useEffect(() => {  
    const get_user = async () => {
      let user = await getObject('user')
      console.log('user', user)
      if (user === null || user === undefined) {
        console.log('no user!!!')
        saveObject('user', {is_authenticated: false})
        set_is_authenticated(false)
        return {is_authenticated: false}
      } else {
        set_is_authenticated(user.is_authenticated)
        
      }
    }
    get_user()    
    
  }, [])
  let location = useLocation();

  if (!is_authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
