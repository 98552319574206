import React, {
  useState,
  useEffect,
} from 'react'
import { Store } from 'react-notifications-component';
import Modal from 'react-modal';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';

import './MailingLists.css'

import NavBar from '../../components/NavBar/NavBar'

import SearchIcon from '../../assets/svg_icons/search.svg'
import CloseIcon from '../../assets/svg_icons/close_1.svg'

import { useNavigate } from 'react-router-dom'
import { 
  fetch_vessel_status_list, 
  add_new_vessel_status_contact, 
  edit_new_vessel_status_contact,
  delete_vessel_status_contact,
  search_vessel_status_mailing_list,
  subscribe_client_to_vessel_status_mailing_list,
} from '../../api/mailinglists'
import {
  fetch_unsubcribed_vessel_status_update_clients,
} from '../../api/clients'


export default function VesselStatusList(props) {
  const navigate = useNavigate()
  const [anchorElement, setAnchorElement] = useState(null);
  const isOptionsOpen = Boolean(anchorElement);
  const [currentItem, setCurrentItem] = useState(null);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isUnsubcribedClientsModalVisible, setIsUnsubcribedClientsModalVisible] = useState(false)
  const [input_values, setInputValues] = useState({
    search_query: '',
  })
  const [newContactInput, setNewContactInput] = useState({
    name: '',
    email: '',
    phone_number: '',
  })
  const [mailing_list, setMailingList] = useState([])
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);  

  const [unsubcribedClients, setUnsubcribedClients] = useState([])
  const [isAllClientsChecked, setIsAllClientsChecked] = useState(false)
  const [openConfirmAddClients, setOpenConfirmAddClients] = React.useState(false);

  useEffect(() => {
    _fetch_vessel_status_list()
    _fetch_unsubcribed_vessel_status_update_clients()
  }, [])

  const _fetch_vessel_status_list = async () => {
    try {
      var res = await fetch_vessel_status_list()
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setMailingList(response.data)
      } else {
        console.log('response message', response.message)
        setMailingList([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log('error message', error.message)
      setMailingList([])
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const _fetch_unsubcribed_vessel_status_update_clients = async () => {
    try {
      var res = await fetch_unsubcribed_vessel_status_update_clients()
      var response = await res.json()
      console.log('fetch_unsubcribed_vessel_status_update_clients response', response)
      if (res.ok) {
        setUnsubcribedClients(response.data.map((item, index) => {
         return {
          ...item,
          isChecked: false,
         }
        }))
      } else {
        console.log('response message', response.message)
        setMailingList([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log('error message', error.message)
      setMailingList([])
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const _search_vessel_status_mailing_list = async (query) => {
    try {
      var res = await search_vessel_status_mailing_list(query)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setMailingList(response.data)
      } else {
        console.log('response message', response.message)
        setMailingList([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log('error message', error.message)
      setMailingList([])
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });

    if(name === 'search_query') {
      if(value === '') {
        _fetch_vessel_status_list()      
        return
      }
      _search_vessel_status_mailing_list(value)
      return
    }
  }

  const handleNewContactInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setNewContactInput({
      ...newContactInput,
      [name]: value
    });
  }

  const closeAddModal = () => {
    setIsAddModalVisible(false)
  }

  const openAddModal = () => {    
    setIsAddModalVisible(true)
  }

  const closeEditModal = () => {
    setIsEditModalVisible(false)
    setNewContactInput({
      ...newContactInput,
      name: '',
      email: '',
      phone_number: '',
    })
  }

  const openEditModal = () => {
    closeOptions()
    setNewContactInput({
      ...newContactInput,
      name: currentItem.name,
      email: currentItem.email,
      phone_number: currentItem.phone_number,
    })
    setIsEditModalVisible(true)
  }

  const closeUnsubscribedClientsModal = () => {
    setIsUnsubcribedClientsModalVisible(false)
  }

  const openUnsubscribedClientsModal = () => {  
    setIsUnsubcribedClientsModalVisible(true)
  }

  const addNew = async (event) => {
    event.preventDefault();

    try {
      var res = await add_new_vessel_status_contact(newContactInput)
      var response = await res.json()
      if(res.ok) {
        await _fetch_vessel_status_list()
        closeAddModal()
        setNewContactInput({
          ...newContactInput,
          name: '',
          email: '',
          phone_number: '',
        })
        Store.addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const edit = async (event) => {
    event.preventDefault();

    try {
      var res = await edit_new_vessel_status_contact(currentItem.id, newContactInput)
      var response = await res.json()
      if(res.ok) {
        await _fetch_vessel_status_list()
        closeEditModal()
        setNewContactInput({
          ...newContactInput,
          name: '',
          email: '',
          phone_number: '',
        })
        Store.addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const seeOptions = (event, item) => {
    setAnchorElement(event.currentTarget);
    setCurrentItem(item)
  };
  
  
  
  const closeOptions = () => {
    setAnchorElement(null);
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const _delete_vessel_status_contact = async () => {
    setOpenDeleteDialog(false);
    try {
      var res = await delete_vessel_status_contact(currentItem.id)
      var response = await res.json()
      if (res.ok) {
       await  _fetch_vessel_status_list()
        Store.addNotification({
          title: "Success",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const customStyles = {
    content: {
      zIndex: 9999999999999,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(20px)',
    }
  };

  const callNumber = () => {    
    closeOptions()
    var number = currentItem.phone_number
    document.getElementById("templink").setAttribute("href", "tel:" + number)    
    document.getElementById("templink").click()   
 }

  const sendEmail = () => {    
    closeOptions()
    var email = currentItem.email
    document.getElementById("templink").setAttribute("href", "mailto:" + email)    
    document.getElementById("templink").click()   
 }

 const toggleIsAllClientsChecked = () => {
  setIsAllClientsChecked(prevState => !prevState)
 }

 const toggleClientChecked = (index) => {
  setUnsubcribedClients(prevState => {
    // var clients = prevState
    // clients[index].isChecked = !clients[index].isChecked

    var clients = prevState.map((data, i) => {      
      if (index === i) {
        return { ...data, isChecked: !data.isChecked };
      } else {
        return data
      }            
    });
    return clients
  })
 }

  const subscribeClients = async shouldAddPersonnel => {
    setOpenConfirmAddClients(false)
    closeUnsubscribedClientsModal()

    try {
      var params = {
        clients: unsubcribedClients,
        add_all_clients: isAllClientsChecked,
        add_personnel: shouldAddPersonnel,
      }
      var res = await subscribe_client_to_vessel_status_mailing_list(params)
      var response = await res.json()
      if(res.ok) {
        await _fetch_vessel_status_list()
        await _fetch_unsubcribed_vessel_status_update_clients()        
        Store.addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  return (
    <div className='veessels-container'>
      <NavBar log_out={props.log_out} />
      <div className='veessels-content'>
        <div className='top-bar'>
          {/* <form> */}
            <span className='search-container'>
              <img
                src={SearchIcon}
                className="search-icon"
                alt="Search"
              />
              <input 
                type="text"
                value={input_values.search_query} 
                name='search_query'
                placeholder='Search'
                onChange={handle_input_change} 
                required
                className="search-input"  
              />
            </span>     
            <a href="mailto:name@email.com" id="templink" style={{color: 'white', display: 'none'}}></a>       
          {/* </form> */}
          <div className='add-new' onClick={openUnsubscribedClientsModal}>
            Add Client
          </div>
          <div className='add-new' onClick={openAddModal}>
            Add New
          </div>
        </div>
        <div class="fixTableHead">
          <table className='table'>
            <thead className='thead'>
              <th 
                className='th'
                style={{
                  width: '30%',
                  paddingLeft: 20,
                }} 
              >
                Name
              </th>
              <th className='th'>
                Email
              </th>
              <th className='th'>
                Phone
              </th>
              {/* <th className='th'>
                Availability
              </th> */}
              {/* <th style={{width: 0}}></th> */}
            </thead>
            <tbody className='tbody'>
              {
                mailing_list.map((item, index) => {
                  return(
                    <tr className='tr' onClick={(event) => seeOptions(event, item)} key={index}>
                      
                      <td 
                        className='td'
                        style={{
                          width: '30%',
                          paddingLeft: 20,
                        }} 
                      >
                        {item.name}
                      </td>
                      <td className='td'>
                        {item.email}
                      </td>
                      <td className='td'>
                        {item.phone_number}
                      </td>
                    </tr> 
                  )
                })
              }
                                          
            </tbody>
          </table>
        </div>
        <Modal
          isOpen={isUnsubcribedClientsModalVisible}
          onRequestClose={closeUnsubscribedClientsModal}
          style={customStyles}
          className="vessel-modal" 
        >
          <button
            className='modal-close-button'
            onClick={closeUnsubscribedClientsModal}
          >
            <img 
              src={CloseIcon}
              alt='Close Button'
            />
          </button>
          <div className='unsubcribed-clients-content'>          
            <div class="fixTableHead">
              <table className='table'>
                <thead className='thead'>
                  <th 
                    className='th'
                    style={{
                      paddingLeft: 20,
                      width: 50,
                    }} 
                  >
                    <input 
                      type="checkbox"
                      checked={isAllClientsChecked}
                      onChange={toggleIsAllClientsChecked}
                    />
                  </th>
                  <th 
                    className='th'
                    style={{
                      paddingLeft: 20,
                    }} 
                  >
                    Name
                  </th>
                  <th className='th'>
                    Email
                  </th>
                  <th className='th'>
                    Phone
                  </th>
                </thead>
                <tbody className='tbody'>
                  {
                    unsubcribedClients.map((item, index) => {
                      var isChecked = isAllClientsChecked || item.isChecked
                      return (
                        <tr className='tr' onClick={() => toggleClientChecked(index)} key={index}>
                      
                          <td 
                            className='td'
                            style={{
                              paddingLeft: 20,
                              width: 50,
                            }} 
                          >
                            <input 
                              type="checkbox"
                              checked={isChecked}
                              // onChange={() => toggleClientChecked(index)}
                            />
                          </td>
                          <td 
                            className='td'
                            style={{
                              paddingLeft: 20,
                            }} 
                          >
                            {item.company_name}
                          </td>
                          <td className='td'>
                            {item.company_email}
                          </td>
                          <td className='td'>
                            {item.company_phone_number}
                          </td>
                        </tr> 
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div 
                className='add-new'  
                onClick={() => setOpenConfirmAddClients(true)}
              >
                Submit
              </div>
            </div>
            
          </div>
        </Modal>
        <Modal
          isOpen={isAddModalVisible}
          onRequestClose={closeAddModal}
          style={customStyles}
          className="vessel-modal" 
        >
          <button
            className='modal-close-button'
            onClick={closeAddModal}
          >
            <img 
              src={CloseIcon}
              alt='Close Button'
            />
          </button>
          <div className='user-content'>          
            <p className='auth-title'>
              Add New
            </p>
            <p className='auth-subtitle'>
              Enter contact information 
            </p>
            <form onSubmit={addNew}>
              <input 
                type='text' 
                value={newContactInput.name} 
                name='name'
                placeholder='Name'
                onChange={handleNewContactInputChange} 
                required
                className="auth-input"                 
              />   
              <input 
                type='email' 
                value={newContactInput.email} 
                name='email'
                placeholder='Email'
                onChange={handleNewContactInputChange} 
                required
                className="auth-input"                 
              />   
              <input 
                type='tel' 
                value={newContactInput.phone_number} 
                name='phone_number'
                placeholder='Phone Number'
                onChange={handleNewContactInputChange} 
                required
                className="auth-input"                 
              />   
              <p className='form-note'>
                Phone number must be in internatonal format and also include the plus (+) sign.
              </p>
              <input type="submit" value="Submit" className="auth-submit-button "/>
            </form>
          </div>
        </Modal>
        <Modal
          isOpen={isEditModalVisible}
          onRequestClose={closeEditModal}
          style={customStyles}
          className="vessel-modal" 
        >
          <button
            className='modal-close-button'
            onClick={closeEditModal}
          >
            <img 
              src={CloseIcon}
              alt='Close Button'
            />
          </button>
          <div className='user-content'>          
            <p className='auth-title'>
              Edit
            </p>
            <form onSubmit={edit}>
              <input 
                type='text' 
                value={newContactInput.name} 
                name='name'
                placeholder='Name'
                onChange={handleNewContactInputChange} 
                required
                className="auth-input"                 
              />   
              <input 
                type='email' 
                value={newContactInput.email} 
                name='email'
                placeholder='Email'
                onChange={handleNewContactInputChange} 
                required
                className="auth-input"                 
              />   
              <input 
                type='tel' 
                value={newContactInput.phone_number} 
                name='phone_number'
                placeholder='Phone Number'
                onChange={handleNewContactInputChange} 
                required
                className="auth-input"                 
              />   
              <p className='form-note'>
                Phone number must be in internatonal format and also include the plus (+) sign.
              </p>
              <input type="submit" value="Submit" className="auth-submit-button "/>
            </form>
          </div>
        </Modal>
        <Menu
          id="basic-menu"
          anchorEl={anchorElement}
          open={isOptionsOpen}
          onClose={closeOptions}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={callNumber}>
            <ListItemIcon>
              <PhoneSharpIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Call</ListItemText>
          </MenuItem>
          <MenuItem onClick={sendEmail}>
            <ListItemIcon>
              <SendSharpIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Send Email</ListItemText>
          </MenuItem>
          <MenuItem onClick={openEditModal}>
            <ListItemIcon>
              <EditSharpIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem 
            onClick={() => {
              closeOptions()
              handleClickOpenDeleteDialog()
            }
          }>
            <ListItemIcon>
              <DeleteSharpIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Remove Contact?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove this contact from this mailing list?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} autoFocus >Take me back</Button>
            <Button onClick={_delete_vessel_status_contact}  color="error">
              Delete it
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openConfirmAddClients}
          onClose={() => setOpenConfirmAddClients(false)} 
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Please Confirm"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Would you like to add the clients contact personnel as a separate subscriber if the clients has a contact personnel?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => subscribeClients(false)} autoFocus >No</Button>
            <Button onClick={() => subscribeClients(true)}  color="success">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* <a href="mailto:name@email.com" id="templink">test</a> */}
      </div>
    </div>
  )
}