import React, {
  useState,
  useEffect,
} from 'react'
import { Store } from 'react-notifications-component';
import { useLocation, useNavigate } from 'react-router-dom'

import './Vessels.css'

import NavBar from '../../components/NavBar/NavBar'

import UploadIcon from '../../assets/svg_icons/upload.svg'
import { edit_vessel } from '../../api/vessels';

export default function EditVessel(props) {
  const navigate = useNavigate()
  const location = useLocation();
  const [input_values, setInputValues] = useState({
    name: '',
    availability: '',
    year: '',
    type: '',
    classification: '',
    description: '',
    length_overall: '',
    breadth_moulded: '',
    depth_moulded: '',
    gross_tonnage: '',
    passenger_capacity: '',
    main_engines: '',
    propulsion: '',
    bow_thruster: '',
    speed: '',
    horse_power: '',
  })
  const [vessel_image, setVesselImage] = useState(null);

  useEffect(() => {
    console.log('props.location.state', location.state)
    let vessel = location.state.vessel
    setInputValues({
      ...input_values,
      name: vessel.name,
      availability: vessel.availability,
      year: vessel.year,
      type: vessel.type,
      classification: vessel.classification,
      description: vessel.description,
      length_overall: vessel.length_overall,
      breadth_moulded: vessel.breadth_moulded,
      depth_moulded: vessel.depth_moulded,
      gross_tonnage: vessel.gross_tonnage,
      passenger_capacity: vessel.passenger_capacity,
      main_engines: vessel.main_engines,
      propulsion: vessel.propulsion,
      bow_thruster: vessel.bow_thruster,
      speed: vessel.speed,
      horse_power: vessel.horse_power,
    })

    let imageName = document.getElementById("imageName")
    imageName.innerText = vessel.image ? vessel.image : ""
  }, [])

  useEffect(() => {
    let input = document.getElementById("inputTag");
    let imageName = document.getElementById("imageName")

    input.addEventListener("change", ()=>{
        let inputImage = document.querySelector("input[type=file]").files[0];

        imageName.innerText = inputImage.name;
    })
  },[])

  const sanitize_msg = msg => {
    // remove urls
    var text = msg.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
    // remove html tags
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if ([event.target.name] == 'image') {
			setVesselImage({
				image: event.target.files,
			});
			return
		}

    setInputValues({
      ...input_values,
      [name]: value
    });
  }

  const handle_submit = async (event) => {
    event.preventDefault();

    try {

      var params = {
        ...input_values,        
      }

      if (vessel_image) {
        params['vessel_image'] = vessel_image
      }

      console.log('params', params)
      // return            
      var res = await edit_vessel(location.state.vessel.id, params)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        navigate('/')
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  return (
    <div className='veessels-container'>
      <NavBar log_out={props.log_out} />
      <div className='add-vessels-section'>
        <p
          className='add-vessel-title'
        >
          Edit Vessel
        </p>
        
        <form className='vessel-form' onSubmit={handle_submit} >
          <p
            className='add-vessel-subtitle'
          >
            Vessel Overview
          </p>
          <div className='vessels-input-fields'>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Name 
              </p>
              <input 
                type='text'
                value={input_values.name} 
                name='name'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Vessel  Availability
              </p>
              <select 
                value={input_values.availability} 
                onChange={handle_input_change} 
                name='availability'
                className="vessel-input-field"   
              >
                <option disabled value="">Select option--</option>
                <option value="Available">Available</option>
                <option value="In Use">In Use</option>
                <option value="Under Maintenance">Under Maintenance</option>
              </select>
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Year Built
              </p>
              <input 
                type='text'
                value={input_values.year} 
                name='year'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Vessel  Type
              </p>
              <input 
                type='text'
                value={input_values.type} 
                name='type'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Classification
              </p>
              <input 
                type='text'
                value={input_values.classification} 
                name='classification'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
          </div>
          <span className='vessel-description-input boxsizingBorder'>
            <p className='vessel-label' >
              Decription
            </p>
            <textarea
              value={input_values.description} 
              name='description'
              placeholder='Type here'
              onChange={handle_input_change} 
              required              
              className='boxsizingBorder'
            /> 
          </span>

          <p
            className='add-vessel-subtitle'
            style={{marginTop: 30}}
          >
            Technical Specifications
          </p>
          <div className='vessels-input-fields'>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Length Overall
              </p>
              <input 
                type='text'
                value={input_values.length_overall} 
                name='length_overall'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Breadth Moulded
              </p>
              <input 
                type='text'
                value={input_values.breadth_moulded} 
                name='breadth_moulded'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            
            <span className='vessel-input'>
              <p className='vessel-label'>
                Depth Moulded
              </p>
              <input 
                type='text'
                value={input_values.depth_moulded} 
                name='depth_moulded'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Gross Tonnage
              </p>
              <input 
                type='text'
                value={input_values.gross_tonnage} 
                name='gross_tonnage'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Passenger Capacity
              </p>
              <input 
                type='text'
                value={input_values.passenger_capacity} 
                name='passenger_capacity'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Main Engines
              </p>
              <input 
                type='text'
                value={input_values.main_engines} 
                name='main_engines'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Speed
              </p>
              <input 
                type='text'
                value={input_values.speed} 
                name='speed'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Horsepower
              </p>
              <input 
                type='text'
                value={input_values.horse_power} 
                name='horse_power'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
          </div>
          <label for="inputTag" className='file-input-label boxsizingBorder'>
            <br/>
            <img 
              src={UploadIcon}
              className='file-upload'
            />
            <p>
              Upload Image
            </p>
            <input 
              id="inputTag" 
              type="file"
              className='file-input'
              name="image"
              onChange={handle_input_change}
              accept="image/png, image/jpg, image/jpeg"
            />
            <br/>
            <span id="imageName"></span>
          </label>

          <span
            style={{
              display: 'flex',
              textAlign: 'center',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 40,
            }}
          >
            <input type="submit" value="SUBMIT" className="submit-button "/>
          </span>
        </form>
      </div>
    </div>
  )
}