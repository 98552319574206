import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { Store } from 'react-notifications-component';

import './Auth.css'
import Logo from '../../assets/images/GMS-Logo.png'

import { 
  set_password
} from '../../api/auth'

export default function SetPassword(props) {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [input_values, setInputValues] = useState({
    new_password_1: '',
    new_password_2: '',
    verification_code: '',
  })
  const [email, setEmail] = useState('')

  useEffect(() => {
    let code = searchParams.get("code")
    let email_params = searchParams.get("email")
    setEmail(email_params || location.state.email)
    setInputValues(prevState => {
      return {
        ...prevState,
        verification_code: code,
      }
    })
  }, [])

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });
  }

  const handle_submit = async (event) => {
    event.preventDefault();
    if (input_values.new_password_1 !== input_values.new_password_2) {
      Store.addNotification({
        title: "New password mismatch",
        message: 'Your new password entry does not match',
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      return
    }
    
    try {
      var params = {
        password: input_values.new_password_1,
        code: input_values.verification_code,
        email,
      }
      var res = await set_password(params)
      var response = await res.json()
      if(res.ok) {
        Store.addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        navigate("/login")
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  return (
    <div className='auth-container'>
      <img 
        src={Logo}
        className='auth-logo'
      />
      <div className='auth-content'>
        <p className='auth-title'>
          Set Password
        </p>
        <p className='auth-subtitle'>
          Enter the verification code that was sent to your email address, and choose a new password
        </p>        
        <form onSubmit={handle_submit}>
          <input 
            type='text' 
            value={input_values.verification_code} 
            name='verification_code'
            placeholder='Verification Code'
            onChange={handle_input_change} 
            required
            className="auth-input"                 
          />   
          <input 
            type='password' 
            value={input_values.new_password_1} 
            name='new_password_1'
            placeholder='New Password'
            onChange={handle_input_change} 
            required
            className="auth-input"                 
          />   
          <input 
            type='password' 
            value={input_values.new_password_2} 
            name='new_password_2'
            placeholder='New Password Again'
            onChange={handle_input_change} 
            required
            className="auth-input"                 
          />   
          <input type="submit" value="Submit" className="auth-submit-button "/>          
        </form>
      </div>
    </div>
  )
}