import React, {
  useState,
  useEffect,
} from 'react'
import { Store } from 'react-notifications-component';
import { useLocation, useNavigate } from 'react-router-dom'

import './Vessels.css'

import NavBar from '../../components/NavBar/NavBar'

import { edit_client } from '../../api/clients';

export default function EditClient(props) {
  const navigate = useNavigate()
  const location = useLocation();
  const [input_values, setInputValues] = useState({
    company_name: '',
    company_email: '',
    company_phone_number: '',
    contact_name: '',
    contact_email: '',
    contact_phone_number: '',
    address: '',
  })

  useEffect(() => {
    console.log('props.location.state', location.state)
    let client = location.state.client
    setInputValues({
      ...input_values,
      company_name: client.company_name,
      company_email: client.company_email,
      company_phone_number: client.company_phone_number,
      contact_name: client.contact_name,
      contact_email: client.contact_email,
      contact_phone_number: client.contact_phone_number,
      address: client.address,
    })
  }, [])

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });
  }

  const handle_submit = async (event) => {
    event.preventDefault();

    try {

      console.log('params', input_values)
      // return            
      var res = await edit_client(location.state.client.id, input_values)
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        navigate('/clients')
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  return (
    <div className='veessels-container'>
      <NavBar log_out={props.log_out} />
      <div className='add-vessels-section'>
        <p
          className='add-vessel-title'
        >
          Edit Client
        </p>
        
        <form className='vessel-form' onSubmit={handle_submit} >
          <div className='vessels-input-fields'>            
            <span className='vessel-input'>
              <p className='vessel-label'>
                Company Name 
              </p>
              <input 
                type='text'
                value={input_values.company_name} 
                name='company_name'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Company Email 
              </p>
              <input 
                type='email'
                value={input_values.company_email} 
                name='company_email'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Company Phone 
              </p>
              <input 
                type='tel'
                value={input_values.company_phone_number} 
                name='company_phone_number'
                placeholder='Type here'
                onChange={handle_input_change} 
                required
                className="vessel-input-field"                 
              /> 
              <p className='form-note'>
                Phone number must be in internatonal format and also include the plus (+) sign.
              </p>
            </span>
          </div>
          <span className='vessel-description-input boxsizingBorder'>
            <p className='vessel-label' >
              Address
            </p>
            <textarea
              value={input_values.address} 
              name='address'
              placeholder='Type here'
              onChange={handle_input_change} 
              className='boxsizingBorder'
            /> 
          </span>
          <p
            className='add-vessel-subtitle'
            style={{marginTop: 30}}
          >
            Contact Details
          </p>
          <div className='vessels-input-fields'>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Contact Name 
              </p>
              <input 
                type='text'
                value={input_values.contact_name} 
                name='contact_name'
                placeholder='Type here'
                onChange={handle_input_change} 
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Contact Email 
              </p>
              <input 
                type='email'
                value={input_values.contact_email} 
                name='contact_email'
                placeholder='Type here'
                onChange={handle_input_change} 
                className="vessel-input-field"                 
              /> 
            </span>
            <span className='vessel-input'>
              <p className='vessel-label'>
                Contact Phone Number 
              </p>
              <input 
                type='tel'
                value={input_values.contact_phone_number} 
                name='contact_phone_number'
                placeholder='Type here'
                onChange={handle_input_change} 
                className="vessel-input-field"                 
              /> 
              <p className='form-note'>
                Phone number must be in internatonal format and also include the plus (+) sign.
              </p>
            </span>
          </div>

          <span
            style={{
              display: 'flex',
              textAlign: 'center',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 40,
              marginBottom: 40,
            }}
          >
            <input type="submit" value="SUBMIT" className="submit-button "/>
          </span>
        </form>
      </div>
    </div>
  )
}