import {baseURL} from './env'

export const fetch_vessels = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'vessels/fetch_vessels'
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const change_vessel_status = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `vessels/change_vessel_status?vessel_id=${params.vessel_id}&new_status=${params.new_status}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const search_vessel = async (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `vessels/search_vessel?query=${query}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const add_vessel = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'vessels/vessels_api'
      var token = localStorage.getItem('token')

      var formdata = new FormData()

      Object.keys(params).forEach(key => {    
        key === 'vessel_image' ? (
          formdata.append("image", params.vessel_image.image[0])
        ) : formdata.append(key, params[key]);
      })

      var response = await fetch(url, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
          // Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: formdata,
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const edit_vessel = async (id, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `vessels/vessels_api?id=${id}`
      var token = localStorage.getItem('token')

      var formdata = new FormData()

      Object.keys(params).forEach(key => {    
        key === 'vessel_image' ? (
          formdata.append("image", params.vessel_image.image[0])
        ) : formdata.append(key, params[key]);
      })

      var response = await fetch(url, {
        method: 'PUT',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
          // Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: formdata,
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const delete_vessel = async id => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + `vessels/vessels_api?id=${id}`
      var token = localStorage.getItem('token')
      var response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

