import React, {
  useEffect,
  useState,
} from 'react'
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom'
import { Store } from 'react-notifications-component';

import '../Vessels/Vessels.css'
import './Users.css'

import NavBar from '../../components/NavBar/NavBar'

import SearchIcon from '../../assets/svg_icons/search.svg'
import CloseIcon from '../../assets/svg_icons/close_1.svg'


import { 
  fetch_users, 
  register_user,
  search_users,
} from '../../api/auth'

export default function UserList(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate()
  const [input_values, setInputValues] = useState({
    search_query: '',
    first_name: '',
    last_name: '',
    email: '',
  })
  const [users, setUsers] = useState([])

  useEffect(() => {    
    _fetch_users()
  }, [])

  const _fetch_users = async () => {
    try {
      var res = await fetch_users()
      var response = await res.json()
      
      if (res.ok) {          
        setUsers(response.data)
      } else {
        console.log('response message', response.message)
        setUsers([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log('error message', error.message)
      setUsers([])
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const _search_users = async (query) => {
    try {
      console.log('query', query)
      var res = await search_users(query)
      var response = await res.json()
      
      if (res.ok) {          
        setUsers(response.data)
      } else {
        console.log('response message', response.message)
        setUsers([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log('error message', error.message)
      setUsers([])
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }


  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });

    if(name === 'search_query') {
      if(value === '') {
        _fetch_users()      
        return
      }
      _search_users(value)
      return
    }
  }


  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handle_submit = async (event) => {
    event.preventDefault();

    try {
      var res = await register_user(input_values)
      var response = await res.json()
      if(res.ok) {
        _fetch_users()
        closeModal()
        Store.addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  const customStyles = {
    content: {
      // top: '50%',
      // left: '50%',
      // right: 'auto',
      // bottom: 'auto',
      // marginRight: '-50%',
      zIndex: 999999999,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(20px)',
    }
  };
  
  return (
    <div className='veessels-container'>
      <NavBar log_out={props.log_out} />
      <div className='veessels-content'>
        <div className='top-bar'>
          {/* <form> */}
            <span className='search-container'>
              <img
                src={SearchIcon}
                className="search-icon"
              />
              <input 
                type="text"
                value={input_values.search_query} 
                name='search_query'
                placeholder='Search'
                onChange={handle_input_change} 
                required
                className="search-input"  
              />
            </span>            
          {/* </form> */}
          <div className='add-new' onClick={openModal}>
            Add New
          </div>
        </div>
        <div >
          <table className='table'>
            <thead className='thead'>
              <th 
                className='th'
                style={{
                  width: '30%',
                  paddingLeft: 20,
                }} 
              >
                First Name
              </th>
              <th className='th'>
                Last Name
              </th>
              <th className='th'>
                Email
              </th>
            </thead>
            <tbody className='tbody'>
              {
                users.map((item, index) => {
                  return(
                    <tr className='tr' key={index}>
                      <td 
                        className='td'
                        style={{
                          paddingLeft: 20,
                        }} 
                      >
                        {item.first_name}
                      </td>
                      <td className='td'>
                        {item.last_name}
                      </td>
                      <td className='td'>
                        {item.email}
                      </td>
                    </tr>    
                  )                            
                })
              }
              
                                          
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className="vessel-modal" 
      >
         <button
          className='modal-close-button'
          onClick={closeModal}
        >
          <img 
            src={CloseIcon}
            alt='Close Button'
          />
        </button>
        <div className='user-content'>          
          <p className='auth-title'>
            Add New User
          </p>
          <p className='auth-subtitle'>
            Enter user information 
          </p>
          <form onSubmit={handle_submit}>
            <input 
              type='text' 
              value={input_values.first_name} 
              name='first_name'
              placeholder='First Name'
              onChange={handle_input_change} 
              required
              className="auth-input"                 
            />   
            <input 
              type='text' 
              value={input_values.last_name} 
              name='last_name'
              placeholder='Last Name'
              onChange={handle_input_change} 
              required
              className="auth-input"                 
            />   
            <input 
              type='email' 
              value={input_values.email} 
              name='email'
              placeholder='Email'
              onChange={handle_input_change} 
              required
              className="auth-input"                 
            />   
            <input type="submit" value="Submit" className="auth-submit-button "/>
          </form>
        </div>
      </Modal>
    </div>
  )
}