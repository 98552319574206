import React, { useState } from 'react'
import { Store } from 'react-notifications-component';
import { useNavigate, useLocation } from 'react-router-dom'

import './Auth.css'
import Logo from '../../assets/images/GMS-Logo.png'
import { getData, saveData, saveObject } from '../../utils/handleStorage'
import { login } from '../../api/auth';
import { sleep } from '../../utils/sleep';

export default function Login(props) {
  const navigate = useNavigate()
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const [input_values, setInputValues] = useState({
    password: '',
    email: '',
  })

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });
  }

  const handle_submit = async (event) => {
    event.preventDefault();
    try {
      var res = await login(input_values)
      var response = await res.json()
      if (res.ok) {
        await saveData('token', response.token)
        let user = {
          ...response.data,
          is_authenticated: true,
        }
        console.log('user', user)
        await saveObject('user', user)
        props.log_in()
        await sleep(5)

        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
      } else {
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
      
    } catch (error) {
      Store.addNotification({
        title: "Error",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
    
  }

  return (
    <div className='auth-container'>
      <img 
        src={Logo}
        className='auth-logo'
      />
      <div className='auth-content'>
        <p className='auth-subtitle'>
          Welcome Back
        </p>
        <p className='auth-title'>
          Log into your account
        </p>
        <form onSubmit={handle_submit}>
          <input 
            type='email' 
            value={input_values.email} 
            name='email'
            placeholder='Email'
            onChange={handle_input_change} 
            required
            className="auth-input"                 
          />   
          <input 
            type='password' 
            value={input_values.password} 
            name='password'
            placeholder='Password'
            onChange={handle_input_change} 
            required
            className="auth-input"                 
          />   
          <input type="submit" value="Log In" className="auth-submit-button "/>
          <a className='auth-text-btn' href='recover-password'>
            Forgot Password?
          </a>
        </form>
      </div>
    </div>
  )
}